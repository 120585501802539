import "../assets/style/circuit_aiw.css";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import $ from "jquery";
import LoadingContent from "./LoadingContent";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../hooks/useWindowSize";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useIsAdmin from "../hooks/useIsAdmin";
import { useUser } from "./UserContext";
import { toast } from "react-toastify";

const CircuitLayout = ({
  circuit_id,
  id_canvas,
  update,
  circleDrivers,
  height,
  width,
  color,
  strokeWidth,
}: {
  circuit_id: number;
  id_canvas: string;
  update?: boolean;
  circleDrivers?: CircleDriver[];
  height?: number;
  width?: number;
  color?: string;
  strokeWidth?: number;
}) => {
  const { t } = useTranslation();
  const { typeUser } = useIsAdmin();
  const { user } = useUser() as any;
  const [loading, setLoading] = useState(true);
  const [windowWidth, windowHeight] = useWindowSize();
  const token = window.localStorage.getItem("userLogged");

  const offsetCoordinate = 35; // offset deppending on strokeWidth
  const [minLat, setMinLat] = useState(Infinity);
  const [minLon, setMinLon] = useState(Infinity);
  const [maxLat, setMaxLat] = useState(0);
  const [maxLon, setMaxLon] = useState(0);
  const [aiw, setAiw] = useState([]);
  const [recording, setRecording] = useState(false);
  useEffect(() => {
    $(`#checked-flag_${id_canvas}`).attr(
      "fill",
      "url(" + window.location.href + "#pattern_checkedflag)"
    );
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/circuit/circuitaiw`,
        {
          params: {
            circuit_id,
          },
        }
      )
      .then(({ data }) => {
        $(".cirdriver").remove();
        setAiw(data.aiw);
        setMinLat(data.minLat);
        setMinLon(data.minLon);
        setMaxLat(data.maxLat);
        setMaxLon(data.maxLon);
        setLoading(false);
      })
      .catch(({ response }) => {
        console.log(response);
        throw new Error(response);
      });
  }, [circuit_id]);

  const recordAIW = () => {
    if (token == null) return;
    if (recording) {
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/saveRecordAIW`,
          { circuit_id, user, token: JSON.parse(token).token }
        )
        .then(() => {
          toast.success(`Circuit recorded successfully`);
        })
        .catch(({ response }) => {
          toast.error("Error!");
          throw new Error(response);
        });
    } else {
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/recordAIW`,
          { user, token: JSON.parse(token).token }
        )
        .then(() => {})
        .catch(({ response }) => {
          toast.error("Error!");
          throw new Error(response);
        });
    }

    setRecording(!recording);
  };

  const replaceAll = (string: string, search: string, replace: string) => {
    return string.split(search).join(replace);
  };
  useEffect(() => {
    const paths = document.querySelectorAll(".mycanvas path");
    paths.forEach((path) => {
      path.removeAttribute("d");
    });
  }, [id_canvas]);

  useEffect(() => {
    let drivers: string[] = [];
    if (circleDrivers != null) {
      circleDrivers?.forEach((driver) => {
        const driver_name = driver.live_name.replace(/[^a-zA-Z0-9]+/g, "");
        drivers.push(driver_name);
        const stroke =
          driver.secondary_color != null ? driver.secondary_color : "#3bd8c9";
        const fill = driver.main_color != null ? driver.main_color : "#000000";
        const lonlat = getLonLat(driver.live_posX, driver.live_posZ * -1);
        if (Number.isNaN(lonlat[0]) || Number.isNaN(lonlat[1])) return;
        if (!$(`.cir-${id_canvas}-${driver_name}`).length) {
          $("#" + id_canvas).find(".svg").append(`
            <g class="cir-${id_canvas}-${driver_name} cirdriver">
              <circle style="stroke:${stroke};stroke-width:1.6871;" fill="${fill}" cx="${lonlat[0]}" cy="${lonlat[1]}" r="10">
              </circle>
              <use id="use-${id_canvas}-${driver_name}" xlink:href="#text-${id_canvas}-${driver_name}" x="${lonlat[0]}" y="${lonlat[1]}" text-anchor="middle" />
            </g>`);
          $("#" + id_canvas).find(".svg defs").append(`
              <text id="text-${id_canvas}-${driver_name}" class="cir-${id_canvas}-${driver_name} cirdriver" dy=".3em"  style="fill:${stroke}" >${driver["live_place"]}</text>`);
          updateSVG();
        }
        if (parseInt(driver.update_time) > 5 || driver.connected === false) {
          $(`.cir-${id_canvas}-${driver_name}`).remove();
          $(`#text-${id_canvas}-${driver_name}`).remove();
        } else {
          const prev_cx = $(`g.cir-${id_canvas}-${driver_name} circle`).attr(
            "cx"
          );
          const prev_cy = $(`g.cir-${id_canvas}-${driver_name} circle`).attr(
            "cy"
          );
          let diffx = null;
          let diffy = null;
          if (prev_cx != null && prev_cy != null) {
            diffx = lonlat[0] - parseFloat(prev_cx);
            diffy = lonlat[1] - parseFloat(prev_cy);
          }

          if (Number.isNaN(diffx)) diffx = lonlat[0];
          if (Number.isNaN(diffy)) diffy = lonlat[1];

          //Now we transform the circle instead of creating him again to have a translation
          const box = $(`g.cir-${id_canvas}-${driver_name} circle`)[0];
          box.style.transform = `translate(${diffx}px, ${diffy}px)`;

          const box2 = $(`g.cir-${id_canvas}-${driver_name} use`)[0];
          box2.style.transform = `translate(${diffx}px, ${diffy}px)`;

          $(`#text-${id_canvas}-${driver_name}`).text(driver["live_place"]);
        }
      });
    }

    var allGs = document.getElementsByTagName("g");
    for (let i = 0; i < allGs.length; i++) {
      let driver_ = allGs[i].className.baseVal.split("cir-")[1];
      driver_ = driver_.split(" ")[0];
      if (!drivers.includes(driver_)) {
        $(`.cir-${id_canvas}-${driver_}`).remove();
      }
    }
  }, [circleDrivers, id_canvas]);

  useEffect(() => {
    //setTimeout(drawCircuit, 1000);
    updateSVG();
    drawCircuit();
  }, [aiw, update, windowWidth, windowHeight]);

  const updateSVG = () => {
    var old_s1_id = $("#" + id_canvas)
      .find(".svg")
      .find(".s1-text:eq(0)");
    const old_canvas_id = old_s1_id.attr("id")?.split("s1-text_")[1];
    if (old_canvas_id != null) {
      $("#" + id_canvas)
        .find(".svg")
        .html(
          replaceAll(
            $("#" + id_canvas)
              .find(".svg")
              .html(),
            old_canvas_id,
            id_canvas
          )
        ); //para que actualice los circulos
    }
  };
  const radians = (degrees: number) => {
    var TAU = 2 * Math.PI;
    return (degrees * TAU) / 360;
  };
  const addMarginsToSVG = (svgElement: any, margin: any) => {
    const bbox = svgElement.getBBox();
    const newViewBox = [
      bbox.x - margin,
      bbox.y - margin,
      bbox.width + margin * 2,
      bbox.height + margin * 2,
    ].join(" ");

    svgElement.setAttribute("viewBox", newViewBox);
  };
  const drawCircuit = () => {
    let track_path_array: { [key: string]: string } = {};

    var first_point = "";
    for (let i = 0; i < aiw.length; i++) {
      const lonlat = getLonLat(aiw[i]["coords"][1], aiw[i]["coords"][0]);
      var new_lon = lonlat[0];
      var new_lat = lonlat[1];

      if (i === 0) {
        first_point = "L " + new_lon + " " + new_lat + " ";
      } else if (i === aiw.length - 1) {
        //Checked flag
        var angleDeg =
          (Math.atan2(
            aiw[i - 1]["coords"][0] - aiw[i]["coords"][0],
            aiw[i - 1]["coords"][1] - aiw[i]["coords"][1]
          ) *
            180) /
            Math.PI +
          90;
        $(`#checked-flag_${id_canvas}`).css(
          "transform",
          "rotate(" + angleDeg + "deg)"
        );
        var xx = new_lon - 18 * Math.cos(radians(angleDeg));
        var yy = new_lat - 18 * Math.sin(radians(angleDeg));
        $(`#checked-flag${id_canvas}`).attr("x", xx).attr("y", yy);
      }
      var variable = "N";
      if (aiw[i]["sector"] === 1) variable = "S1";
      if (aiw[i]["sector"] === 2) variable = "S2";
      if (aiw[i]["sector"] === 3) variable = "S3";

      if (variable !== null && track_path_array[variable] === undefined) {
        track_path_array[variable] = "M " + new_lon + " " + new_lat + " ";
        $("#" + variable.toLowerCase() + "-text" + id_canvas)
          .attr("x", new_lon + 10)
          .attr("y", new_lat - 10);
      } else track_path_array[variable] += "L " + new_lon + " " + new_lat + " ";

      //To link the sectors
      if (aiw.length > i + 1 && aiw[i + 1]["sector"] - aiw[i]["sector"] === 1) {
        const latlon = getLonLat(
          aiw[i + 1]["coords"][1],
          aiw[i + 1]["coords"][0]
        );
        new_lon = latlon[0];
        new_lat = latlon[1];

        track_path_array[variable] += "L " + new_lon + " " + new_lat + " ";
      }
    }
    //Close the circuit
    if (
      track_path_array["S3"] != null &&
      Object.entries(track_path_array).length > 0
    ) {
      const last_sector_coords = track_path_array["S3"].split(" ");
      const last_x = parseFloat(
        last_sector_coords[last_sector_coords.length - 3]
      );
      const last_y = parseFloat(
        last_sector_coords[last_sector_coords.length - 2]
      );
      const first_point_coords = first_point.split(" ");
      const first_x = parseFloat(
        first_point_coords[first_point_coords.length - 3]
      );
      const first_y = parseFloat(
        first_point_coords[first_point_coords.length - 2]
      );
      if (
        !(
          last_x > first_x + 35 ||
          last_x < first_x - 35 ||
          last_y > first_y + 35 ||
          last_y < first_y - 35
        )
      ) {
        track_path_array["S3"] += first_point;
      }
    }

    if (track_path_array["S3"] == null) {
      $(`#s3-text_${id_canvas}`).hide();
      $(`#s2-text_${id_canvas}`).hide();
      $(`#s1-text_${id_canvas}`).hide();
    }

    $(`#${id_canvas} .path_s1`).attr("d", track_path_array["S1"]);
    $(`#${id_canvas} .path_s2`).attr("d", track_path_array["S2"]);
    $(`#${id_canvas} .path_s3`).attr("d", track_path_array["S3"]);

    var svg = document
      .getElementById(id_canvas)
      ?.getElementsByClassName("svg")[0] as unknown as SVGSVGElement;

    // Añadir un margen de 5 unidades
    addMarginsToSVG(svg, 5);

    var bbox = svg.getBBox();
    if (bbox.width !== 0) {
      const currHeight = bbox.y + bbox.height;
      //if (lastHeight == null || parseFloat(lastHeight) < 15) {
      svg.setAttribute("width", bbox.x + bbox.width + "px");
      svg.setAttribute("height", currHeight + "px");
      //}
    }
  };

  const getLonLat = (lon: number, lat: number) => {
    if ($(`#${id_canvas}`).parent().css("height") == null && height != null)
      return [0, 0];
    let max_width_circuit =
      width != null
        ? width - offsetCoordinate
        : parseFloat($(`#${id_canvas}`).css("width").split("px")[0]) -
          offsetCoordinate;
    let max_height_circuit =
      height != null
        ? height - offsetCoordinate
        : parseFloat($(`#${id_canvas}`).css("height").split("px")[0]) -
          offsetCoordinate;

    // Calculamos el rango de latitud y longitud
    const latRange = maxLat - minLat;
    const lonRange = maxLon - minLon;

    // Calcula las proporciones en relación con las dimensiones deseadas del SVG
    const widthProportion = max_width_circuit / lonRange;
    const heightProportion = max_height_circuit / latRange;

    // Encuentra la escala basada en la proporción más pequeña
    const scale = Math.min(widthProportion, heightProportion);

    // Calcula las coordenadas transformadas
    const transformedLon = (lon - Math.min(minLon, maxLon)) * scale;
    const transformedLat = (lat - Math.min(minLat, maxLat)) * scale;

    return [transformedLon, transformedLat];
  };

  return (
    <div id={id_canvas} className="mycanvas-container">
      <div className="mycanvas flex-center">
        {/*<Link to={t("routes:Livetiming")} className="button-red blink top-left">
          Live Timing!
        </Link>*/}
        <svg className="svg">
          {circleDrivers != null && (
            <defs>
              <pattern
                id="pattern_checkedflag"
                patternUnits="userSpaceOnUse"
                width="6"
                height="6"
              >
                <rect
                  className="checker"
                  x="0"
                  width="3"
                  height="3"
                  y="0"
                  fill="black"
                />
                <rect
                  className="checker"
                  x="3"
                  width="3"
                  height="3"
                  y="3"
                  fill="black"
                />
              </pattern>
              <text
                id={`s1-text_${id_canvas}`}
                fontSize="20"
                stroke={`${color != null ? color : "#5ba60e"}`}
                className="s1-text"
              >
                S1
              </text>
              <text
                id={`s2-text_${id_canvas}`}
                fontSize="20"
                stroke={`${color != null ? color : "#a81643"}`}
              >
                S2
              </text>
              <text
                id={`s3-text_${id_canvas}`}
                fontSize="20"
                stroke={`${color != null ? color : "#177cbc"}`}
              >
                S3
              </text>
              <rect
                id={`checked-flag_${id_canvas}`}
                width="35"
                height="10"
                /*fill={`url("${window.location.href}#pattern_checkedflag)`}*/
              />
            </defs>
          )}
          <path
            className="path_s1"
            stroke={`${color != null ? color : "#5ba60e"}`}
            strokeWidth={`${strokeWidth != null ? strokeWidth : "5"}`}
            fill="none"
          ></path>
          <path
            className="path_s2"
            stroke={`${color != null ? color : "#a81643"}`}
            strokeWidth={`${strokeWidth != null ? strokeWidth : "5"}`}
            fill="none"
          ></path>
          <path
            className="path_s3"
            stroke={`${color != null ? color : "#177cbc"}`}
            strokeWidth={`${strokeWidth != null ? strokeWidth : "5"}`}
            fill="none"
          ></path>
          <use id={`s1-text${id_canvas}`} xlinkHref={`#s1-text_${id_canvas}`} />
          <use id={`s2-text${id_canvas}`} xlinkHref={`#s2-text_${id_canvas}`} />
          <use id={`s3-text${id_canvas}`} xlinkHref={`#s3-text_${id_canvas}`} />
          <use
            id={`checked-flag${id_canvas}`}
            xlinkHref={`#checked-flag_${id_canvas}`}
          />
        </svg>
        {!loading && aiw.length === 0 && (
          <>
            {" "}
            <p className="errorMessage">{t("CircuitNotFound")}</p>
            {typeUser === 2 && circleDrivers?.length === 1 && (
              <button id="ac-recorder-button" onClick={() => recordAIW()}>
                <FontAwesomeIcon icon={faCircle} />
                {recording ? "Recording" : "Record AIW"}
              </button>
            )}
          </>
        )}
        {loading && <LoadingContent />}
      </div>
    </div>
  );
};

export default CircuitLayout;
