import axios from "axios";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import "../../assets/style/raceentries.css";
import LoadingContent from "../../components/LoadingContent";
import { Link, useParams } from "react-router-dom";
import DateFormat from "../../components/DateFormat";
import Flag from "../../components/Flag";
import CircuitLayout from "../../components/CircuitLayout";
import { getAbbreviation } from "../../utils/Standings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faTrophy } from "@fortawesome/free-solid-svg-icons";

interface Race {
  name: string;
  date: string;
  circuit_id: number;
  circuit: string;
  flag_code: string;
  winners: string[];
  laps: string[];
  flag: string;
  id: number;
  flag_codes: string[];
  flags: string[];
}

const Calendar = () => {
  const { t } = useTranslation();
  let { season } = useParams();
  const [data, setData] = useState<Race[]>([]);
  const [theme, setTheme] = useState("light");
  const [championship, setChampionship] = useState("");

  useEffect(() => {
    const theme_ = window.localStorage.getItem("theme");
    setTheme(theme_ != null && theme_ === "dark" ? "dark" : "light");
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/championship/getCalendar`,
        {
          params: {
            champ: season,
          },
        }
      )
      .then(({ data }) => {
        setData(data.calendar);
        setChampionship(data.championship);
        document.title = `LVF1 - ${t("Calendar")} ${championship}`;
      })
      .catch(({ response }) => {
        throw new Error(response);
      });
  }, [season, theme]);

  const getLapsText = (laps: string) => {
    if (laps.includes("/")) {
      const split = laps.split("/");
      return (
        <Trans
          i18nKey="HillClimbLaps"
          values={{
            laps: split[0],
            valid_laps: split[1],
          }}
          components={{
            bold: <strong />,
          }}
        />
      );
    }
    return laps + " " + t("Laps").toUpperCase();
  };

  const abbreviateGP = (gp_name: string) => {
    if (gp_name.includes("GRAND PRIX"))
      return gp_name.replace("GRAND PRIX", "GP");
    return gp_name;
  };
  if (data == null) return <LoadingContent />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className={`no-second-padding frame-turqoise`}>
        <h3 className="text-center frame-title">
          {t("Calendar").toUpperCase()} {championship}
        </h3>
        <div
          id="calendar-parent"
          className={`flex-center full-width align-top`}
        >
          {data.map((race, index) => {
            return (
              <div className="calendar-race" key={index}>
                <label className="round-calendar">{index + 1}</label>
                <label>
                  <Flag flag_code={race.flag_code} name={race.flag} />
                  <b>{abbreviateGP(race.name.toUpperCase())}</b>
                </label>
                <label className="circuit-calendar">{race.circuit}</label>
                <label className="date-calendar">
                  <DateFormat date={race.date} format={"short"} />
                </label>
                <label className="laps-calendar">
                  {getLapsText(race.laps[0])}
                </label>
                <CircuitLayout
                  circuit_id={race.circuit_id}
                  id_canvas={`circuit-${race.id}`}
                  color={theme == "dark" ? "#ffffff" : "#000000"}
                  strokeWidth={2}
                />
                <div className="grid">
                  {race.winners != null && (
                    <label>
                      <FontAwesomeIcon
                        icon={faTrophy}
                        className="trophy-calendar"
                      />
                      <span className="winnerC-title">
                        {race.winners.length > 1
                          ? t("Winners").toUpperCase()
                          : t("Winner").toUpperCase()}
                      </span>
                    </label>
                  )}
                  <div className="flex-center winners-calendar">
                    {race.winners != null ? (
                      race.winners.map((driver, index) => {
                        return (
                          <label className="ellipsis-label">
                            <Flag
                              flag_code={race.flag_codes[index]}
                              name={race.flags[index]}
                            />
                            <span>{getAbbreviation(driver).toUpperCase()}</span>
                          </label>
                        );
                      })
                    ) : (
                      <i>{t("ToBeRaced")}</i>
                    )}
                  </div>
                </div>

                {race.winners != null && (
                  <a
                    href={`${t("routes:GrandPrix")}/${encodeURIComponent(
                      race.name.replace(/ /g, "")
                    )}`}
                    className="driver bold"
                    title={race.name}
                    target="_blank" // Abre en una nueva pestaña
                    rel="noopener noreferrer" // Seguridad recomendada
                  >
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="arrowResults"
                    />
                    {t("Results")}
                  </a>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
