import React from "react";
import { useState } from "react";
import { Nav, Form, Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "../../../../components/UserContext";
import axios from "axios";

const Login = ({ toast }: { toast: any }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [validated, setValidated] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const handleForgotPasswordClose = () => setShowForgotPassword(false);
  const handleForgotPasswordShow = () => setShowForgotPassword(true);

  const [body, setBody] = useState({
    username: "",
    password: "",
    translations: [],
  });
  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    setBody({
      ...body,
      [name]: value,
    });
  };

  const { login, setUserConnection } = useUser() as any;

  const handleSignUp = () => {
    handleClose();
    window.scrollTo(0, 0);
  };

  const handleForgotPassword = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (!(form !== null && form.checkValidity() === false)) {
      body.translations = [
        t("NoUserFound"),
        t("ResetEmailSent"),
        t("ErrorSendingEmail"),
        t("ResetPassword"),
        t("ResetPasswordTxt1"),
        t("ResetPasswordTxt2"),
        t("routes:ResetPassword"),
      ];

      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/user/forgotPassword`,
          {
            body,
          }
        )
        .then((response) => {
          toast.success(response.data);
          handleForgotPasswordClose();
        })
        .catch(({ response }) => {
          toast.error(response.data);
          throw new Error(response);
        });
    }
    setValidated(true);
  };

  return (
    <>
      <Nav.Link onClick={handleShow}>{t("Login")}</Nav.Link>
      <Modal size="sm" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Login")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("Username")}</Form.Label>
              <Form.Control
                name="username"
                type="text"
                value={body.username}
                onChange={inputChange}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>{t("Password")}</Form.Label>
              <Form.Control
                name="password"
                type="password"
                value={body.password}
                onChange={inputChange}
              />
            </Form.Group>
            {error === true && <span className="error">{t("LogInError")}</span>}
            <Link
              to={t("routes:Home")}
              className="driver link-login"
              onClick={handleSignUp}
            >
              {t("SignUpFromLogIn")}
            </Link>
            <Link
              to={"#"}
              className="driver link-login"
              onClick={() => {
                handleForgotPasswordShow();
                handleClose();
              }}
            >
              {t("ForgotPassword")}
            </Link>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Close")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              login(body, function (response: any) {
                if (response === true) {
                  toast.success(t("LogInSuccess"));
                  setUserConnection();
                } else setError(true);
                body.password = "";
              });
            }}
          >
            {t("Login")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="sm"
        show={showForgotPassword}
        onHide={handleForgotPasswordClose}
      >
        <Form noValidate validated={validated} onSubmit={handleForgotPassword}>
          <Modal.Header closeButton>
            <Modal.Title>{t("ResetPassword")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <small>{t("ForgotPwdInfo")}</small>
            <Form.Group className="mb-3" controlId="forgotPasswordInput">
              <Form.Control
                name="username"
                type="text"
                required
                value={body.username}
                onChange={inputChange}
                placeholder={`${t("Username")} / ${t("Email")}`}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleForgotPasswordClose}>
              {t("Close")}
            </Button>
            <Button variant="primary" type="submit">
              {t("Send")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default Login;
