import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  getColor,
  teamLogo,
  teamBackground,
  getStandingsPositionStyle,
  getDriverNameLink,
} from "../../utils/Standings";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTable from "react-data-table-component";
import Flag from "../Flag";

interface Rows {
  pos: number;
  JSXDriver: ReactElement<any, any>;
  JSXClass: ReactElement<any, any>;
  gps: number;
  poles: number;
  podiums: number;
  wins: number;
  points: number;
  fastestlaps: number;
  laps_lead: number;
  pointsJSX: any;
  catalog: null | number;
}

const DriverStats = ({
  data,
  champData,
}: {
  data: DriverStats[];
  champData: ChampData;
}) => {
  const { t } = useTranslation();
  const rows: Rows[] = [];
  {
    data.map((driver, index) => {
      rows.push({
        pos: driver.position,
        JSXDriver: (
          <div
            className="teamdriver-results-container text-left"
            style={{
              background: teamBackground(driver.team_id, driver.main_color),
            }}
          >
            <div className="logo-results-container flex-center">
              {!/^[0-9]+$/.test(driver.team_id) ? (
                <Flag flag_code={driver.flag_code} name={driver.flag_name} />
              ) : (
                teamLogo(driver.team_id, driver.team_logo, driver.team_name)
              )}
            </div>
            {getDriverNameLink(driver, t)}
          </div>
        ),
        JSXClass: (
          <React.Fragment>
            {driver.class !== null && (
              <label
                className="class_content"
                style={{
                  background:
                    driver.class_color != null ? driver.class_color : "#179387",
                }}
              >
                {driver.class.toUpperCase()}
              </label>
            )}
          </React.Fragment>
        ),
        catalog: driver.catalog,
        gps: driver.gps === null ? 0 : driver.gps,
        poles: driver.poles === null ? 0 : driver.poles,
        podiums: driver.podiums === null ? 0 : driver.podiums,
        wins: driver.wins === null ? 0 : driver.wins,
        fastestlaps: driver.fastestlaps === null ? 0 : driver.fastestlaps,
        laps_lead: driver.laps_lead === null ? 0 : driver.laps_lead,
        points: driver.points === null ? 0 : driver.points,
        pointsJSX: (
          <div
            className={`${
              typeof driver.position === "number" &&
              getStandingsPositionStyle(driver.is_dsq ? "DSQ" : driver.position)
            }`}
          >
            {driver.points}
            <small>
              {driver.full_points_web != null && `(${driver.full_points_web})`}
            </small>
          </div>
        ),
      });
    });
  }

  const columnas =
    champData.type_championship === 3
      ? [
          {
            name: "#",
            selector: (row: { pos: number }) => row.pos,
            sortable: true,
            width: "60px",
          },
          {
            name: t("Driver"),
            selector: (row: { JSXDriver: any }) => row.JSXDriver,
            width: "205px",
          },
          {
            name: t("Class"),
            selector: (row: { JSXClass: any }) => row.JSXClass,
            width: "100px",
            sortable: true,
            sortFunction: (a: Rows, b: Rows) => {
              if (a.catalog == null || b.catalog == null) return -1;
              return a.catalog - b.catalog;
            },
          },
          {
            name: t("GPs"),
            selector: (row: { gps: number }) => row.gps,
            sortable: true,
            width: "100px",
          },
          {
            name: t("Wins"),
            selector: (row: { wins: number }) =>
              row.wins === 0 ? "" : row.wins,
            sortable: true,
            width: "120px",
          },
          {
            name: t("Podiums"),
            selector: (row: { podiums: number }) =>
              row.podiums === 0 ? "" : row.podiums,
            sortable: true,
            width: "120px",
          },
          {
            name: t("HotLaps"),
            selector: (row: { fastestlaps: number }) =>
              row.fastestlaps === 0 ? "" : row.fastestlaps,
            sortable: true,
            width: "130px",
          },
          {
            name: t("Points"),
            selector: (row: { pointsJSX: any }) => row.pointsJSX,
            sortable: true,
            width: "100px",
            sortFunction: (a: Rows, b: Rows) => {
              return a.points - b.points;
            },
          },
        ]
      : [
          {
            name: "#",
            selector: (row: { pos: number }) => row.pos,
            sortable: true,
            width: "60px",
          },
          {
            name: t("Driver"),
            selector: (row: { JSXDriver: any }) => row.JSXDriver,
            width: "205px",
          },
          {
            name: t("Class"),
            selector: (row: { JSXClass: any }) => row.JSXClass,
            width: "100px",
            sortable: true,
            sortFunction: (a: Rows, b: Rows) => {
              if (a.catalog == null || b.catalog == null) return -1;
              return a.catalog - b.catalog;
            },
          },
          {
            name: t("GPs"),
            selector: (row: { gps: number }) => row.gps,
            sortable: true,
            width: "100px",
          },
          {
            name: t("Poles"),
            selector: (row: { poles: number }) =>
              row.poles === 0 ? "" : row.poles,
            sortable: true,
            width: "120px",
          },
          {
            name: t("Wins"),
            selector: (row: { wins: number }) =>
              row.wins === 0 ? "" : row.wins,
            sortable: true,
            width: "120px",
          },
          {
            name: t("Podiums"),
            selector: (row: { podiums: number }) =>
              row.podiums === 0 ? "" : row.podiums,
            sortable: true,
            width: "120px",
          },
          {
            name: t("HotLaps"),
            selector: (row: { fastestlaps: number }) =>
              row.fastestlaps === 0 ? "" : row.fastestlaps,
            sortable: true,
            width: "130px",
          },
          {
            name: t("LapsLead"),
            selector: (row: { laps_lead: number }) =>
              row.laps_lead === 0 ? "" : row.laps_lead,
            sortable: true,
            width: "130px",
          },
          {
            name: t("Points"),
            selector: (row: { pointsJSX: any }) => row.pointsJSX,
            sortable: true,
            width: "100px",
            sortFunction: (a: Rows, b: Rows) => {
              return a.points - b.points;
            },
          },
        ];

  return (
    <div className="frame-turqoise">
      <h3 className="text-center frame-title">
        {t("DriverStatsStandings").toUpperCase()}
      </h3>
      <div className="flex-container">
        <div className="flex">
          <div
            className={`data-standings ${
              data.length > 0 && data[0].class == null ? "hide-class" : ""
            }`}
          >
            <DataTable columns={columnas} data={rows} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverStats;
