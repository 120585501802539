import { useState } from "react";
import Title from "../../../components/Admin/Title";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useUser } from "../../../components/UserContext";
import { toast } from "react-toastify";

const GenerateXMLHCFile = () => {
  const [sessions, setSessions] = useState(3);
  const [heats, setHeats] = useState(3);
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [fileInputs, setFileInputs] = useState<any>({});
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");

  const handleFileChange = (e: any, index: string) => {
    const newFileInputs = { ...fileInputs };
    newFileInputs[index] = e.target.files[0];
    setFileInputs(newFileInputs);
  };

  const submitForm = async (event: any) => {
    if (token == null) return;
    event.preventDefault();
    const formData = new FormData();
    formData.append("user", user);
    formData.append("token", JSON.parse(token).token);
    formData.append("sessions", sessions + "");
    formData.append("heats", heats + "");

    for (const index in fileInputs) {
      if (fileInputs.hasOwnProperty(index)) {
        const file = fileInputs[index];
        if (file) {
          formData.append(`file_${index}`, file);
        }
      }
    }

    try {
      await axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/generateXMLHCFile`,
          formData
        )
        .then((response) => {
          // Create a Blob from the response
          const blob = new Blob([response.data], { type: "application/xml" });

          // Create a URL for the Blob
          const url = window.URL.createObjectURL(blob);

          // Create a link and set it up for downloading
          const a = document.createElement("a");
          a.href = url;
          a.download = "data.xml";

          // Simulate a click on the link to start downloading
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(({ response }) => {
          toast.error(`Error: ${response.data.message}`);
          setUploading(false);
          setValidated(false);
          throw new Error(response);
        });
    } catch (error) {
      console.error("Error al enviar los archivos:", error);
    }
  };

  const renderSessions = () => {
    const formGroups = [];

    for (let i = 0; i < sessions; i++) {
      formGroups.push(
        <Form.Group className="form-group" as={Row} md="12" key={`h${i}`}>
          {renderHeats(i + 1)}
        </Form.Group>
      );
    }

    return formGroups;
  };

  const renderHeats = (session: number) => {
    const formGroups = [];
    const md = 12 / heats;
    for (let i = 0; i < heats; i++) {
      formGroups.push(
        <Form.Group className="form-group" as={Col} md={md} key={`s${i}`}>
          <Form.Label>
            S{session} H{i + 1} (*)
          </Form.Label>
          <Form.Control
            name={`file_${session}_${i + 1}`}
            type="file"
            accept="text/xml,application/json"
            required
            onChange={(e) => handleFileChange(e, `${session}_${i + 1}`)}
          />
          <Form.Control.Feedback type="invalid">
            Field required
          </Form.Control.Feedback>
        </Form.Group>
      );
    }
    return formGroups;
  };

  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Generate XML HC File" />
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Sessions (*)</Form.Label>
              <Form.Control
                name="sessions"
                type="number"
                required
                min={1}
                onChange={(e) => setSessions(parseInt(e.target.value))}
                value={sessions}
              />
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="6">
              <Form.Label>Heats (*)</Form.Label>
              <Form.Control
                name="heats"
                type="number"
                min={1}
                required
                onChange={(e) => setHeats(parseInt(e.target.value))}
                value={heats}
              />
            </Form.Group>
            {renderSessions()}
          </Row>
          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Download XML HC File
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default GenerateXMLHCFile;
