const checkPasswordInvalidity = (password: string, password_conf: string) => {
  if (password.length >= 8 && password !== password_conf) return true;
  return false;
};

const checkPasswordInvalidityLength = (password: string) => {
  if (password !== "" && password.length < 8) return true;
  return false;
};

export { checkPasswordInvalidity, checkPasswordInvalidityLength };
