import axios from "axios";
import io from "socket.io-client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { md5Cipher } from "../utils/MD5Cipher";
import { Form, Button, Col, Row } from "react-bootstrap";
import {
  checkPasswordInvalidity,
  checkPasswordInvalidityLength,
} from "../utils/Validations";

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();
  const [body, setBody] = useState({
    password_conf: "",
    password: "",
  });
  const [validated, setValidated] = useState(false);
  document.title = "LVF1 - " + t("ResetPassword");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const formData = new FormData();
    if (
      !checkPasswordInvalidity(body.password, body.password_conf) &&
      !checkPasswordInvalidityLength(body.password) &&
      body.password !== ""
    ) {
      formData.append("tokenExp", t("TokenExpired"));
      formData.append("passwordROK", t("PasswordResetOK"));
      formData.append("password", md5Cipher(body.password));
      if (token != null) formData.append("token", token);
      try {
        axios
          .post(
            `${
              process.env.NODE_ENV === "development"
                ? "http://localhost:5500"
                : "https://ligavirtualf1.es:5500"
            }/api/user/resetPassword`,
            formData
          )
          .then(({ data }) => {
            toast.success(`${t("PasswordResetOK")}`);
            navigate(`${t("routes:Home")}`);
          })
          .catch(({ response }) => {
            toast.error(`${t(response.data)}`);
          });
      } catch (error) {}
    }
  };

  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    setBody({
      ...body,
      [name]: value,
    });
  };

  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div id="regulation" className="frame-turqoise">
        <h3 className="text-center frame-title">
          {t("ResetPassword").toUpperCase()}
        </h3>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="text-center form-resetpwd"
        >
          <div className="flex-center">
            <Row className="reset-pwd">
              <Form.Group as={Col} md="6">
                <Form.Label>{t("Password")}</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  value={body.password}
                  onChange={inputChange}
                  isInvalid={checkPasswordInvalidityLength(body.password)}
                />
                <Form.Control.Feedback type="invalid">
                  {t("PasswordShortError")}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>{t("ConfirmPassword")}</Form.Label>
                <Form.Control
                  name="password_conf"
                  type="password"
                  value={body.password_conf}
                  onChange={inputChange}
                  isInvalid={checkPasswordInvalidity(
                    body.password,
                    body.password_conf
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {t("PasswordConfError")}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
          </div>
          <Button variant="primary" type="submit">
            {t("UpdatePassword")}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
