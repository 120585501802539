import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/style/profile.css";
import "react-toastify/dist/ReactToastify.css";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import { useUser } from "../../components/UserContext";
import { md5Cipher } from "../../utils/MD5Cipher";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import ErrorPage from "../ErrorPage";
import Cropper from "react-cropper";
import CropperUser from "../../components/CropperUser";
import LoadingContent from "../../components/LoadingContent";
import {
  checkPasswordInvalidity,
  checkPasswordInvalidityLength,
} from "../../utils/Validations";

const UpdateProfile = () => {
  const { t } = useTranslation();
  document.title = "LVF1 - " + t("EditProfile");

  const cropperRef = useRef<HTMLImageElement>(null);
  const [validated, setValidated] = useState(false);
  const [file, setFile] = useState<any>("");
  const [file2, setFile2] = useState("");
  const [text, setText] = useState("");
  const { user } = useUser() as any;
  const [body, setBody] = useState({
    mail: "",
    birthday: "",
    password: "",
    password_conf: "",
    username: "",
    name: "",
    surname: "",
  });
  const inputChange = ({ target }: { target: any }) => {
    const { name, value } = target;
    setBody({
      ...body,
      [name]: value,
    });
  };
  const handleChange = (e: any) => {
    const file = e.target.files[0]; // accessing file
    setFile(file);
    setText(file.name);
  };

  useEffect(() => {
    if (user != null) {
      let token = window.localStorage.getItem("userLogged");
      if (token == null) return;
      axios
        .get(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/user/getUser`,
          {
            params: {
              user,
              token: JSON.parse(token).token,
            },
          }
        )
        .then(({ data }) => {
          setBody({
            ...body,
            mail: data.mail,
            birthday: data.birthday,
            username: data.username,
            name: data.name,
            surname: data.surname,
          });
        })
        .catch(({ response }) => {
          throw new Error(response);
        });
    }
  }, [user]);

  const submitForm = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (!(form !== null && form.checkValidity() === false)) {
      let token = window.localStorage.getItem("userLogged");
      if (token == null) return;
      const formData = new FormData();
      formData.append("file", file2);
      formData.append("username", user);
      formData.append("name", body.name);
      formData.append("surname", body.surname);
      formData.append("birthday", body.birthday);
      formData.append("mail", body.mail);
      formData.append("token", JSON.parse(token).token);
      if (
        !checkPasswordInvalidity(body.password, body.password_conf) &&
        !checkPasswordInvalidityLength(body.password)
      ) {
        if (body.password !== "")
          formData.append("password", md5Cipher(body.password));

        axios
          .post(
            `${
              process.env.NODE_ENV === "development"
                ? "http://localhost:5500"
                : "https://ligavirtualf1.es:5500"
            }/api/user/updateUser`,
            formData
          )
          .then(({ data }) => {
            toast.success(`${t("ChangesSaved")}`);
          })
          .catch(({ response }) => {
            throw new Error(response);
          });
      }
    }
    setValidated(true);
  };

  if (user === null || body.username === "") return <LoadingContent />;
  return (
    <div className="full-height app-container flex-center-top padding-md">
      <div id="update-profile-container" className="frame-turqoise">
        <p className="main-title frame-title">{t("EditProfile")}</p>
        <small className="mandatory-fields">(*) {t("RequiredFields")}</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Form.Group className="form-group">
            <Form.Label>{t("Username")} (*)</Form.Label>
            <Form.Control
              name="username"
              type="text"
              value={body.username}
              disabled
            />
          </Form.Group>
          <Row className="mb-3">
            <Form.Group as={Col} md="6">
              <Form.Label>{t("Name")} (*)</Form.Label>
              <Form.Control
                name="name"
                type="text"
                value={body.name}
                onChange={inputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                {t("FieldRequired")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>{t("Surname")} (*)</Form.Label>
              <Form.Control
                name="surname"
                type="text"
                value={body.surname}
                onChange={inputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                {t("FieldRequired")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group className="form-group">
            <Form.Label>E-mail (*)</Form.Label>
            <Form.Control
              name="mail"
              type="text"
              value={body.mail}
              onChange={inputChange}
              required
              pattern=".+@.+"
            />
            <Form.Control.Feedback type="invalid">
              {t("EmailError")}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>{t("Birthdate")}</Form.Label>
            <Form.Control
              name="birthday"
              type="date"
              value={body.birthday}
              onChange={inputChange}
            />
          </Form.Group>
          <Row className="mb-3">
            <Form.Group as={Col} md="6">
              <Form.Label>{t("Password")}</Form.Label>
              <Form.Control
                name="password"
                type="password"
                value={body.password}
                onChange={inputChange}
                isInvalid={checkPasswordInvalidityLength(body.password)}
              />
              <Form.Control.Feedback type="invalid">
                {t("PasswordShortError")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>{t("ConfirmPassword")}</Form.Label>
              <Form.Control
                name="password_conf"
                type="password"
                value={body.password_conf}
                onChange={inputChange}
                isInvalid={checkPasswordInvalidity(
                  body.password,
                  body.password_conf
                )}
              />
              <Form.Control.Feedback type="invalid">
                {t("PasswordConfError")}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group className="form-group">
            <Form.Label className="relative">
              {t("DriverPhoto")}
              <span
                className="profile-bubble"
                data-tooltip={t("DriverPhotoHelp")}
                data-flow="right"
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="label-icon"
                />
              </span>
            </Form.Label>
            <div className="file-container">
              <label htmlFor="filePicker">{t("ChoosePhoto")}</label>
              <label>{text}</label>
              <Form.Control
                name="photo"
                type="file"
                accept="image/*"
                onChange={handleChange}
                id="filePicker"
                style={{ visibility: "hidden" }}
              />
              <CropperUser file={file} setFile2={setFile2} />
            </div>
          </Form.Group>
          <div className="flex-center">
            <Button variant="primary" type="submit">
              {t("SubmitChanges")}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default UpdateProfile;
