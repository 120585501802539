import "../../assets/style/admin.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import useIsAdmin from "../../hooks/useIsAdmin";

const AdminHome = () => {
  const [loading, setLoading] = useState(true);
  document.title = "LVF1 - Admin";
  const { typeUser } = useIsAdmin();

  if (typeUser < 2) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container page-transition flex flex-around basel">
      <div id="admin-container" className="row">
        {(typeUser === 2 || typeUser === 3) && (
          <div className="col-md-4">
            <label>GP</label>
            {typeUser === 2 && (
              <>
                <Link to={`/Admin/UploadGP`}>Upload GP</Link>
                <Link to={`/Admin/UpdateGPResults`}>Update GP Results</Link>
                <Link to={`/Admin/UpdateGP`}>Update GP</Link>
                <Link to={`/Admin/LivetimingRaceHC`}>Livetiming Race HC</Link>
                <Link to={`/Admin/GenerateXMLHCFile`}>
                  Generate XML HC File
                </Link>
              </>
            )}
            <Link to={`/Admin/PostPenaltiesGP`}>Post Penalties GP</Link>
          </div>
        )}
        {typeUser === 2 && (
          <>
            <div className="col-md-4">
              <label>DRIVERS</label>
              <Link to={`/Admin/UpdateDriver`}>Update Driver</Link>
              <Link to={`/Admin/TitularDrivers`}>Titular Drivers</Link>
              <Link to={`/Admin/AddAssignment`}>Add Assignment</Link>
              <Link to={`/Admin/RemoveAssignment`}>Remove Assignment</Link>
              <Link to={`/Admin/DriverPhoto`}>Driver photos</Link>
              <Link to={`/Admin/ChangeLivetimingUsername`}>
                Change Livetiming Username
              </Link>
            </div>
            <div className="col-md-4">
              <label>TEAMS</label>
              <Link to={`/Admin/CreateTeam`}>Create Team</Link>
              <Link to={`/Admin/UpdateTeam`}>Update Team</Link>
              <Link to={`/Admin/TeamCorrelations`}>Team Correlations</Link>
              <Link to={`/Admin/ChangeDriverTeam`}>
                Change Team Driver (Standings)
              </Link>
              <Link to={`/Admin/AddTeamChampionship`}>
                Add Team Championship
              </Link>
              <Link to={`/Admin/DeleteTeamChampionship`}>
                Delete Team Championship
              </Link>
              <Link to={`/Admin/CreateClass`}>Create Class</Link>
            </div>
            <div className="col-md-4">
              <label>CHAMPIONSHIP</label>
              <Link to={`/Admin/AddChampionship`}>Add Championship</Link>
              <Link to={`/Admin/RemoveChampionship`}>Remove Championship</Link>
              <Link to={`/Admin/CreateScoringVariant`}>
                Create Scoring Variant
              </Link>
              <Link to={`/Admin/CreateCustomScoringName`}>
                Create Custom Scoring Name
              </Link>
              <Link to={`/Admin/SkipShift`}>Skip Shifts</Link>
              <Link to={`/Admin/DQDriver`}>DQ Driver</Link>
            </div>
            <div className="col-md-4">
              <label>GENERAL</label>
              <Link to={`/Admin/CreateFlag`}>Create Flag</Link>
              <Link to={`/Admin/CreateGame`}>Create Game</Link>
              <Link to={`/Admin/CreateCircuit`}>Create Circuit</Link>
              <Link to={`/Admin/UpdateCircuit`}>Update Circuit</Link>
              <Link to={`/Admin/UpdateRegulation`}>Update Regulation</Link>
              <Link to={`/Admin/Config`}>Config</Link>
              <Link to={`/Admin/UpdateIndexPhotos`}>Update Index Photos</Link>
            </div>
          </>
        )}
        {(typeUser === 2 || typeUser === 4) && (
          <div className="col-md-4">
            <label>NEWS</label>
            <Link to={`/Admin/CreateNew`}>Create New</Link>
            <Link to={`/Admin/UpdateNews`}>Update News</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminHome;
